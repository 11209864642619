import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'produto-search',
  templateUrl: './produto-search.component.html',
  styleUrls: ['./produto-search.component.scss']
})
export class ProdutoSearchComponent implements OnInit {

  @Input("classificacao") classificacao:any = [];  
  @Input("categorias") categorias:any       = [];
  @Input("categoria") categoria  = null;
  @Input("termo") termo  = null;
  @ViewChild("contentPesquisa") contentPesquisa:any;   
  @Output("changeSearch") changeSearch = new EventEmitter();
  public dataItem:any = {};
  public loader = false;
  public comboCategoria = [];

  constructor(
    private app: AppService,
    private api: ApiService
  ){}  

  /**
   * 
   * Search
   * 
   */
  search(){

    try{

      this.loader = true;

      this.api.produto().search({
        nome: this.dataItem.nome,
        classificacao: [this.dataItem.classificacao],
        produto_categoria_id: this.dataItem.produto_categoria_id
      }).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.changeSearch.emit(response.data);  

        }else{

          let error   = this.api.formatError(response);
          this.app.info("Houve um erro. Tente nvamente, por favor. Detalhe: "+error.message);

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.app.info("Houve um erro. Tente nvamente, por favor. Detalhe: "+error.message);

      });
    

    }catch(e){

    }

  }
  /**
   * 
   * Change classificacao
   * 
   */
  _changeClassificacao(status,item){

    if(status){
      this.dataItem.classificacao.push(item.id); 
    }else{

      for (let index = 0; index < this.dataItem.classificacao.length; index++) {
        
        if(this.dataItem.classificacao[index] == item.id){

          this.dataItem.classificacao.splice(index,1);
          break;
        
        }
        
      }

    }

  }
  /**
  * 
  * Open pesquisa
  * 
  */
  _openPesquisa(){

    if(this.contentPesquisa.nativeElement.classList.contains("slideInDown")){
  
      this.contentPesquisa.nativeElement.classList.remove("slideInDown");
  
    }else{
  
      this.contentPesquisa.nativeElement.classList.add("slideInDown");
  
    }
  
  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: this.termo,
      classificacao: [],
      produto_categoria_id: null
    }

    if(this.categoria != null){

      this.dataItem.produto_categoria_id = this.categoria.id;

    }

  }
  /**
   * 
   * Set combo categoria
   * 
   */
  setComboCategoria(){

    let data = [];

    for (let index = 0; index < this.categorias.length; index++) {
      
      data.push({
        value: this.categorias[index].id,
        text: this.categorias[index].titulo
      });
      
    }

    this.comboCategoria = data;

  }
  /**
   * 
   * Change categoria
   * 
   */
  _changeCategoria(data){

    this.dataItem.produto_categoria_id = data.value;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setComboCategoria();
    this.initDataItem(); 
  }
    

}
