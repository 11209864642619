<div class="bandeiras template-{{template}}" *ngIf="bandeiras.length > 0">
      <div class="title">
            <span>Aceitamos:</span>
      </div>
      <div class="content">
            <div class="item" *ngFor="let b of bandeiras">
                  <img [src]="b.imagem" [alt]="b.titulo" width="100%"/>
            </div>
      </div>
</div>
