import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;
declare var Splide:any;

@Component({
  selector: 'banner-splider',
  templateUrl: './banner-splide.component.html',
  styleUrls: ['./banner-splide.component.scss']
})
export class BannerSplideComponent implements OnInit,AfterViewInit {

  @Input("data")  data               = null; 
  @Input("template")  template       = null; 
  @Input("classCustom")  classCustom = null; 
  @ViewChild("banner") banner:ElementRef;
  @ViewChild("slide") slide:any;
  public banners                     = []; 
  public sliderElement:any           = null;
  public isMobile = false;

  constructor(
    private route: Router
  ){}

  /**
   * 
   * Select banner
   * 
   **/
  selectBanner(i){

    $(this.banner.nativeElement).slick("goTo",i);

  }
  /**
   * 
   * Inicializa o banner
   * 
   */
  initBanner(){

    $(this.banner.nativeElement).slick({
      infinite: true,
      autoplay: true,
      dots: false,
      arrows: false
    });
    $(this.banner.nativeElement).slick('refresh');

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkProduto(banner){

    return "/servicos/"+banner.categoria_apelido+"/"+banner.produto_apelido;

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkCategoria(banner){

    return "/servicos/"+banner.categoria;

  }
  /**
   * 
   * To cidades
   * 
   */
  toCidades(){

    this.route.navigateByUrl("/a-serra-gaucha");   
    
  }
  /**
   * 
   * Open link
   * 
   */
  openLink(dataItem:any){

    let url =null;

    switch(dataItem.link_tipo){
      case 1:

        window.open(dataItem.link,dataItem.link_target); 
        return false;

      break
      case 2:

        url = this.getLinkProduto(dataItem);

        this.route.navigateByUrl(url); 
        return false;

      break
      case 3:

        url = this.getLinkCategoria(dataItem);

        this.route.navigateByUrl(url);
        return false;

      break
    }

  }
  /**
   * 
   * Prev
   * 
   */
  prevSlide(){

    $(this.banner.nativeElement).slick("slickPrev");

  }
  /**
   * 
   * Next
   * 
   */
  nextSlide(){

    $(this.banner.nativeElement).slick("slickNext"); 
  
  }
  /**
   * 
   * Init splide
   * 
   */
   initSplide(){

    try{

      this.sliderElement = new Splide(this.slide.nativeElement, {
        type   : 'loop',
      }).mount();  

    }catch(e){
    
    }

  }
  /**
   * 
   * On resize
   * 
   */
  onResize(){

    this.setMobile();

  }
  /**
   * 
   * Set mobile
   * 
   */
  setMobile(){

    let width = window.innerWidth;

    if(width < 700){
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }

    this.setBanners();

  }
  /**
   * 
   * Set banners
   * 
   * 
   */
  setBanners(){

    let data = [];

    for (let index = 0;index < this.data.banners.length; index++) {

      if(this.isMobile && (this.data.banners[index].imagem_mobile !== null && this.data.banners[index].imagem_mobile !== "")){
        this.data.banners[index].foto = this.data.banners[index].imagem_mobile;
      }else{
        this.data.banners[index].foto = this.data.banners[index].imagem;
      }
      data.push(this.data.banners[index]); 
      
    }

    this.banners = data;

    setTimeout(() => {
      //this.initSplide();
    },10);

  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){}
  /**
   * 
   * After View loaded
   * 
   */
  ngAfterViewInit(): void {
    
    this.setMobile();
    setTimeout(() => {
      this.initSplide();
    },100);

  }

}
