import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'bandeiras',
  templateUrl: './bandeiras.component.html',
  styleUrls: ['./bandeiras.component.scss']
})
export class BandeirasComponent implements OnInit {

  public bandeiras = [];
  @Input("template") template:any = 1;

  constructor(
    private data: DataService
  ){}

  /**
   * 
   * Set
   * 
   */
  set(){

    this.bandeiras = this.data.getBandeiras();

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.set();
  }

}
